.header {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  height: 120px; // value from live site
  position: relative;

  @include mq('medium') {
    height: auto;
  }

  &__logo {
    width: 140px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: var(--emu-common-other-z-index-layer);
    display: inline-block;
    text-align: right;
    vertical-align: bottom;
    float: right;

    @include mq('medium') {
      right: 0;
      position: relative;
      width: 18%;
      top: 0;
    }

    img {
      width: 100%;
      display: inline-block;
    }
  }

  .header__hamburger.emu-button-v2.emu-button {
    padding-top: var(--emu-common-spacing-none);
    vertical-align: middle;
    margin-top: 25px;
    padding-left: 20px;
    border: none;

    &,
    &:hover,
    &:active,
    &:focus {
      background-color: var(--emu-common-colors-white);
      border-color: var(--emu-common-colors-white);
    }

    @include mq('medium') {
      display: none;
    }
  }

  &__wrapper {
    position: absolute;
    background-color: var(--emu-common-colors-white);
    z-index: var(--emu-common-other-z-index-layer);
    top: 120px;
    padding: 20px;
    display: none;
    width: 100%;

    @include mq('medium') {
      vertical-align: bottom;
      display: inline-flex;
      padding: var(--emu-common-spacing-none);
      position: relative;
      top: 0;
      justify-content: space-between;
    }

    &.js-toggle-on {
      display: block;

      @include mq('medium') {
        display: inline-flex;
      }
    }
  }

  .emu-navigation__content-wrapper {
    position: relative;

    nav {
      > ul {
        flex-direction: column;

        @include mq('medium') {
          flex-direction: row;
        }

        > li {
          &:first-child {
            > a {
              @include mq('medium') {
                padding-left: var(--emu-common-spacing-none);
              }
            }
          }

          > a {
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            text-decoration: none;
            font-size: var(--emu-common-font-sizes-wide-medium);
            color: var(--emu-common-colors-grey-600);
            border: none;
            font-weight: var(--emu-semantic-font-weight-500);

            @include mq('medium') {
              padding-top: var(--emu-common-spacing-none);
              padding-bottom: var(--emu-common-spacing-none);
              padding-left: 15px;
              padding-right: 15px;
            }

            &:hover {
              background-color: var(--emu-common-colors-white);
              color: var(--emu-common-colors-red-400);
            }

            &.is-active {
              color: var(--emu-common-colors-red-400);
            }
          }
        }
      }
    }
  }

  &__lang {
    display: inline-block;
    width: auto;
    padding: var(--emu-common-spacing-none);
    margin-left: auto;

    p {
      color: var(--emu-common-colors-grey-600);
      font-weight: var(--emu-semantic-font-weight-500);
      font-size: var(--emu-common-font-sizes-wide-medium);
      line-height: normal;

      a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
          color: var(--emu-common-colors-red-400);
        }
      }

      > .emphasis {
        color: var(--emu-common-colors-grey-800);
        font-weight: var(--emu-semantic-font-weight-bold);
      }

      a .emphasis {
        color: var(--emu-common-colors-red-400);
        font-weight: var(--emu-common-font-weight-regular);
      }
    }
  }
}

[lang='fr_ca'] {
  .header {
    .emu-navigation__content-wrapper {
      nav {
        > ul > li > a {
          font-size: var(--emu-common-font-sizes-wide-medium);

          @include mq('medium') {
            font-size: 15px;
          }

          @include mq('x-large') {
            font-size: var(--emu-common-font-sizes-wide-medium);
          }
        }
      }
    }
  }
}
