html {
  -webkit-text-size-adjust: 100%;
}

html,
body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-body);
  line-height: var(--emu-common-line-heights-narrow-body);

  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: var(--emu-common-spacing-none);
  }
}

body {
  -webkit-overflow-scrolling: touch;
  font-family: var(--emu-semantic-font-families-body);
  max-width: 1314px; //as per live
  margin-left: auto;
  margin-right: auto;

  @include mq('medium') {
    padding-left: 20px;
    padding-right: 20px;
  }
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

sup {
  font-size: 11.6667px;
  line-height: 17.5px;
  position: relative;
  vertical-align: super;
  top: 0;
  display: inline-block;
  margin-right: 1px;
  letter-spacing: normal;
}

p,
li {
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
}
