.teaser {
    .hero {
      height: 300px; //as per live
      position: relative;
  
      @include mq('medium') {
        height: 730px; //as per live
      }
  
      &.emu-teaser.has-bg-image {
        background-position: center center;
  
        @include mq('medium') {
          background-position: 60% center;
        }
      }
  
      .content-container {
        right: 5%;
        width: 38%;
        position: absolute;
        padding-left: var(--emu-semantic-spacing-none);
        padding-right: var(--emu-semantic-spacing-none);
        padding-top: var(--emu-semantic-spacing-none);
        padding-bottom: var(--emu-semantic-spacing-none);
  
        @include mq('medium') {
          bottom: 0;
          width: 40%; //as per live
          top: auto;
        }
  
        //breakpoint is added to match live
        @include mq('x-large') {
          width: 368px; //as per live
          top: 20%;
        }
  
        .content .title-lockup {
          width: -webkit-fill-available;
  
          //breakpoint is added to match live
          @include mq('768px') {
            max-width: 100%;
          }
        }
      }
  
      .emu-teaser {
        
        &__title {
          letter-spacing: 4px;
          color: var(--emu-common-colors-grey-700);
          font-family: var(--emu-semantic-font-families-body);
  
          @include mq('small-plus') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
          }
  
          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          }

          &:after {
            content: '';
            height: 8px; //as per live
            background-color: var(--emu-semantic-colors-error-400);
            width: 80%; //as per live
            margin-top: 10px;
            display: block;

            @include mq('medium') {
              height: 13px; //as per live
              margin-top: 30px;
              margin-bottom: 25px;
            }
          }
        }
  
        &__description {
          display: none;
  
          @include mq('medium') {
            display: block;
            margin-bottom: 5px;
  
            p {
              color: var(--emu-common-colors-grey-700);
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              line-height: var(--emu-semantic-line-heights-narrow-large);
  
              u {
                text-decoration: none;
                color: var(--emu-semantic-colors-error-400);
              }
            }
          }
        }
      }
    }
  
    &__description {
      width: fit-content;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-top: 20px;
  
      u {
        text-decoration: none;
        color: var(--emu-semantic-colors-error-400);
      }
  
      p {
        @include mq('small-plus') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
        }
      }
    }
  }

  [lang='fr_ca'] {
    .teaser {
      .hero {
        .content-container {
          top: 5%;
          width: 30%; //as per live

          @include mq('small-plus') {
            width: 40.25%; //as per live
          }

          //breakpoint is added to match live
          @include mq('953px') {
            top: 10%;
            width: 40%; //as per live
          }

          @include mq('x-large') {
            width: 368px; //as per live
          }

          .emu-teaser {
            &__description {
              u sup {
                font-size: 13.3px;
                line-height: var(--emu-common-line-heights-wide-medium);
                display: inline;
              }
            }
          }
        }
      }

      &__description {
        u sup {
          display: inline;
          
          @include mq('small-plus') {
            font-size: 19.1px;
            line-height: 28.6px;
          }
        }
      }
    }
  }