#getting-started {
  .teaser {
    .hero {
      @include mq('medium') {
        height: 900px; //as per live
      }

      &.emu-teaser.has-bg-image {
        @include mq('medium') {
          background-position: center center;
        }

        .teaser-container {
          overflow: hidden;

          @include mq('medium') {
            overflow: visible;
          }
        }
      }

      .content-container {
        width: 40%; //as per live
        right: 0;

        @include mq('medium') {
          top: 10%;
          width: 33%; //as per live
          right: 5%;
        }

        //breakpoint is added to match live
        @include mq('x-large') {
          width: 368px; //as per live
        }

        .content .title-lockup {
          @include mq('medium') {
            margin-bottom: var(--emu-semantic-spacing-none);
          }
        }
      }

      .emu-teaser {
        &__description {
          padding-bottom: var(--emu-semantic-spacing-none);

          p {
            @include mq('medium') {
              margin-bottom: 25px;
            }
          }
        }

        &__subtext {
          display: none;

          @include mq('medium') {
            display: block;
            padding-top: var(--emu-semantic-spacing-none);

            h2 {
              margin-top: var(--emu-semantic-spacing-none);
              margin-bottom: 20px;
              color: var(--emu-semantic-colors-error-400);
              font-size: var(--emu-common-font-sizes-wide-medium);
              line-height: var(--emu-semantic-line-heights-narrow-small);
              font-family: var(--emu-common-font-families-serif);
              font-weight: var(--emu-common-font-weight-bold);
            }
          }
        }
      }
    }

    &__description {
      padding-bottom: var(--emu-semantic-spacing-none);

      p {
        margin-bottom: 18px;
      }
    }

    &__subtext {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;

      h2 {
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: 20px;
        color: var(--emu-semantic-colors-error-400);
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: var(--emu-semantic-line-heights-narrow-small);
        font-family: var(--emu-common-font-families-serif);
        font-weight: var(--emu-common-font-weight-bold);

        @include mq('small-plus') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
        }
      }
    }
  }
}

[lang='fr_ca'] {
  #getting-started {
    .teaser {
      .hero {
        .content-container {
          top: auto;
          width: 46%; //as per live

          @include mq('small-plus') {
            width: 35%; //as per live
          }

          @include mq('medium') {
            width: 30%; //as per live
          }

          @include mq('x-large') {
            top: 10%;
            width: 368px; //as per live
          }
        }

        .emu-teaser {
          &__title {
            font-size: 18px;

            @include mq('small-plus') {
              font-size: 35px;
            }

            //breakpoint is added to match live
            @include mq('953px') {
              font-size: 45px;
            }

            //breakpoint is added to match live
            @include mq('1041px') {
              font-size: 50px;
            }

            //breakpoint is added to match live
            @include mq('1138px') {
              font-size: var(--emu-semantic-font-sizes-wide-xxxl);
            }
          }

          &__description {
            p {
              font-size: 13px;
              line-height: 23.4px;
              display: block !important; //to override display inline fro AEM

              @include mq('x-large') {
                font-size: 16px;
                line-height: var(--emu-semantic-line-heights-wide-large);
              }

              u sup {
                font-size: 10.83px;
                line-height: 19.5px;

                @include mq('x-large') {
                  font-size: 13.3px;
                  line-height: 24px;
                }
              }
            }
          }

          &__subtext {
            h2 {
              sup {
                font-size: 13.3px;
                display: inline;
                line-height: var(--emu-semantic-line-heights-narrow-small);
              }
            }
          }
        }
      }

      &__description p {
        margin-bottom: 19px;
      }

      &__subtext {
        padding-bottom: 18px;

        h2 {
          line-height: 22px;

          sup {
            @include mq('small-plus') {
              font-size: 19.1px;
            }
          }
        }
      }
    }

    .find-a-clinic{
      > .container:first-child, &-affiliate  {
        margin-bottom: 39px; 

        @include mq('medium') {
          margin-bottom: 20px;
          }
      }
    }
  }
}
