.footer {
  border-top-width: 3px;
  border-top-color: var(--emu-semantic-colors-error-400);
  border-top-style: solid;
  padding-top: 40px;
  padding-bottom: 40px;

  @include mq('medium') {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &.cmp-container {
    margin-top: 20px;

    @include mq('medium') {
      margin-top: 40px;
    }
  }

  &__img {
    width: 150px; //as per live

    &.cmp-container {
      margin-bottom: 5px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

    @include mq('medium') {
      padding-left: var(--emu-semantic-spacing-none);
      padding-right: var(--emu-semantic-spacing-none);
      padding-top: var(--emu-semantic-spacing-none);
      padding-bottom: var(--emu-semantic-spacing-none);
    }

    &-links {
      font-size: var(--emu-semantic-font-sizes-wide-small);
      line-height: 18.4px;
      padding-top: var(--emu-common-spacing-xxs);
      letter-spacing: 0.04px;

      &.cmp-container {
        margin-bottom: 12px;
      }

      ul {
        padding-left: var(--emu-semantic-spacing-none);
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: var(--emu-semantic-spacing-none);

        li {
          display: inline;
          padding-right: 3px;

          a {
            font-weight: var(--emu-semantic-font-weight-500);
            line-height: normal;
            color: var(--emu-common-colors-black);

            &:hover,
            &:focus,
            &:active {
              color: var(--emu-common-colors-black);
            }
          }

          &:not(:last-child) {
            &:after {
              content: '|';
              padding-left: 3.5px;
              padding-right: 3.5px;

              @include mq('small-plus') {
                padding-left: 9.5px;
                padding-right: 9.5px;
              }

              @include mq('medium') {
                padding-left: 11.1px;
              }
            }
          }
        }
      }
    }

    &-fineprint {
      p {
        font-size: 9px;
        margin-bottom: 10px;
        line-height: 13.5px;
      }
    }
  }
}

[lang='fr_ca'] {
  .footer__content-links {
    ul {
      display: flex;
      flex-direction: column;

      @include mq('small-plus') {
        display: block;
      }
      
      li {
          margin-bottom: var(--emu-common-spacing-xs);

          @include mq('small-plus') {
            margin-bottom: var(--emu-common-spacing-none);
          }
      
        &:not(:last-child) {
          &:after {
            content: '';

            @include mq('small-plus') {
              content: '|';
              padding-left: 9.5px;
              padding-right: 9.5px;
            }

            @include mq('medium') {
              padding-left: 11.1px;
            }
          }
        }

        u{
          text-underline-offset: 1px; // these properties added to make underline consistent as on some devices the underline position and thickness are varying.
          text-decoration-thickness: 1px;
        }
      }
    }
  }
}
