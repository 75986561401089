// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// centers the contents present in an element
.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// utilities classes for text
[class*='u-text-color'],
[class*='u-title'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  a,
  span {
    color: inherit;
  }
}

.u-text-color--white {
  color: var(--emu-common-colors-white) !important;
}

.u-text-color--black {
  color: var(--emu-common-colors-black) !important;
}

.u-text-color--red{
  color:var(--emu-common-colors-red-400) ;
}

.u-text-color--dark-pink{
  color:var(--emu-common-colors-blush-800) ;
}

.u-text-color--teal{
  color:var(--emu-common-colors-teal-500) ;
}

.u-text-color--light-grey{
  color:var(--emu-common-colors-grey-100) ;
}

.u-text-color--grey{
  color:var(--emu-common-colors-grey-600) ;
}

.u-text-color--dark-grey{
  color:var(--emu-common-colors-grey-700) ;
}

.u-text-color--darker-grey{
  color:var(--emu-common-colors-grey-800) ;
}

// utility classes for the background colors
.u-bg-color--red{
  background-color:var(--emu-common-colors-red-400) ;
}

.u-bg-color--violet{
  background-color:var(--emu-common-colors-blush-800) ;
}

.u-bg-color--teal{
  background-color:var(--emu-common-colors-teal-500) ;
}

.u-bg-color--light-grey{
  background-color:var(--emu-common-colors-grey-100) ;
}

.u-bg-color--grey{
  background-color:var(--emu-common-colors-grey-600) ;
}

.u-bg-color--dark-grey{
  background-color:var(--emu-common-colors-grey-700) ;
}

.u-bg-color--darker-grey{
  background-color:var(--emu-common-colors-grey-800) ;
}

// hides br in mobile and shows above 1024px
.u-hide-br-in-mobile {
  br {
    display: none;

    @include mq('medium') {
      display: block;
    }
  }
}

// hides br above 768px
.u-hide-br-in-desktop {
  br {
    @include mq('medium') {
      display: none;
    }
  }
}