.error {
  padding-top: var(--emu-common-spacing-large);
  padding-bottom: var(--emu-common-spacing-large);

  &__title {
    h2 {
      margin-top: var(--emu-semantic-spacing-none);
      margin-bottom: var(--emu-semantic-spacing-none);
    }
  }

  &__text {
    margin-bottom: var(--emu-common-spacing-large);
    margin-top: var(--emu-common-spacing-large);
    width: 83.33333333%; //as per live

    p {
      font-family: var(--emu-common-font-families-sans);
      font-size: 18px;
    }
  }

  .button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  &__button {
    //to increase the specificity
    &.emu-button-v2.emu-button.emu-button__primary-filled {
      background-color: var(--emu-common-colors-transparent);
      font-size: var(--emu-common-font-sizes-narrow-large);

      &:hover,
      &:active,
      &:focus {
        background-color: rgba(240, 248, 255, 0.185);
      }
    }
  }
}
