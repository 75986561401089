.find-a-clinic {
  @include mq('medium') {
    margin-top: 20px;
    display: flex;
  }

  > .container:first-child {
    margin-bottom: 20px;

    @include mq('medium') {
      padding-right: 20px;
      margin-bottom: var(--emu-semantic-spacing-none);
      width: 50%;
    }
  }

  > .container:last-child {
    @include mq('medium') {
      width: 50%;
    }
  }

  //this is first half of a block containing red colored section
  &-information {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;

    &__title {
      .emu-title__text {
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: 25px;
        font-family: var(--emu-common-font-families-serif);
        font-weight: var(--emu-semantic-font-weight-500);
        font-size: var(--emu-semantic-font-sizes-narrow-xxl);
        line-height: normal;
      }
    }

    &__description {
      margin-bottom: 20px;

      @include mq('x-large') {
        width: 75%;
      }
    }

    &__button {
      position: absolute;

      span {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: normal;
      }

      //to increase specificity
      &.emu-button-v2.emu-button {
        border: none;
      }
    }
  }

  //this is second half of a block containing purple colored section
  &-affiliate {
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;

    &__container {
      padding: 20px;
      position: relative;

      @include mq('medium') {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
        padding-bottom: 30px;
      }
    }

    &__content {
      z-index: var(--emu-common-other-z-index-layer);
      position: relative;
      width: 100%;

      @include mq('x-large') {
        width: 56%;
      }
    }

    &__logo {
      width: 102px; // as per live site
      margin-bottom: 46px;
    }

    &__title {
      .emu-title__text {
        margin: var(--emu-common-spacing-none);
        font-weight: var(--emu-semantic-font-weight-bold);
      }
    }

    &__description {
      margin-top: 20px;
      margin-bottom: 20px;

      @include mq('medium') {
        margin-top: 17px;
        margin-bottom: 19px;
      }

      .emphasis {
        @include mq('x-large') {
          white-space: nowrap;
        }
      }

      p {
        font-family: var(--emu-common-font-families-sans);
        line-height: 23px;
      }

      b {
        letter-spacing: -0.11px;
        font-weight: var(
          --emu-common-font-weight-black
        ); // live has 700, but 900 looks more similar
      }
    }

    &__subtext {
      p {
        margin-bottom: 20px;
        font-family: var(--emu-common-font-families-sans);

        &:last-child {
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          font-weight: var(
            --emu-common-font-weight-black
          ); // live has 700, but 900 looks closer to the site
          line-height: 14px;

          a {
            font-weight: var(--emu-semantic-font-weight-500);
          }
        }
      }
    }

    &__img {
      float: right;
      position: absolute;
      z-index: var(--emu-common-other-z-index-layer);
      top: 60px;
      bottom: auto;
      width: 150px;
      right: -20px;

      @include mq('medium') {
        right: 0;
      }

      @include mq('x-large') {
        width: 213px; //as per live
        bottom: 32px;
        top: unset;
      }
    }
  }

  &__block-height {
    @include mq('medium') {
      height: 470px; //as per live site
      margin-bottom: var(--emu-common-spacing-none);
    }
  }
}

[lang='fr_ca'] {
  .find-a-clinic-affiliate {
    @include mq('medium') {
    margin-bottom: var(--emu-common-spacing-none);
    }

    &__logo {
      margin-bottom: 21px;
    }

    &__title {
      .emu-title__text {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 20.8px;
      }
    }

    &__description {
      margin-top: 5px;
      margin-bottom: 10px;

      p {
        line-height: 21px;
        letter-spacing: 0.6px;

        @include mq('small-plus'){
          letter-spacing: -0.13px;
        }
      }

      b {
        font-weight: var(--emu-common-font-weight-bold);
        letter-spacing: 0.1px;
      }
    }

    &__subtext {
      p {
        &:first-child {
          margin-top: 5px;
          margin-bottom: 11px;
          font-weight: var(--emu-semantic-font-weight-500);
          line-height: 20px;
        }

        &:last-child {
          font-weight: var(--emu-common-font-weight-bold);
        }
      }
    }

    &__img {
      @include mq('x-large') {
        bottom: 0;
      }
    }
  }
}
